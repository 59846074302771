<template>
  <div>
    <el-table
      :data="suppliers"
      v-loading="isLoading"
      v-el-table-infinite-scroll="loadNewData"
      style="width: 100%"
      @cell-click="clickCell">
      <el-table-column label="Нэр">
        <template slot-scope="scope">
            <span>{{ scope.row.Username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Регистер">
        <template slot-scope="scope">
          <span>{{scope.row.UserCreateDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Хэрэглэгчийн статус">
        <template slot-scope="scope">
          <!-- <span>{{scope.row.UserStatus}}</span> -->
          <div v-if="scope.row.UserStatus === 'CONFIRMED'">
          <el-tag type="success">баталгаажсан</el-tag>
          </div>
          <div v-else-if="scope.row.UserStatus === 'UNCONFIRMED'">
          <el-button size="small" type="primary" @click="confirmSignUp(scope.row.Username)">Баталгаажуулах</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Хаяг">
        <template slot-scope="scope">
          <span>{{scope.row.email}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import supplierEditDialog from './supplierEdit'
import services from '../../../../helpers/services'
export default {
  props: [
    'suppliers',
    'getSupplierAdmins',
    'loadNewData'
  ],
  data () {
    return {
      count: 10,
      isLoading: false,
      loading: false
    }
  },
  methods: {
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    confirmSignUp (user) {
      services.confirmSignUp({ user_name: user }).then(response => {
        if (response.status === 'success') {
          this.getSupplierAdmins()
          this.alertReporter('Амжилттай', 'Амжилттай баталгаажсан.', 'success')
        } else {
          this.alertReporter('Амжилттай', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    clickCell (row, column, cell, event) {
      if (column.label !== 'Хэрэглэгчийн статус') {
        this.$router.push(
          {
            name: 'editSupplierAdmin',
            params: {
              user_name: row.Username
            }
          }
        )
      }
    }
  }
}
</script>
