<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Нийлүүлэгчийн удирдлага</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="getSupplierAdmins">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="5">
                                          <el-select v-model="statusValue" @change="getSupplierAdmins" placeholder="Статусаар хайх">
                                            <el-option
                                              v-for="(item, index) in status"
                                              :key="index"
                                              :label="item.label"
                                              :value="item.value">
                                            </el-option>
                                          </el-select>
                                      </el-col>
                                  </el-row>
                              </div>
                              <supplier-admin-table :suppliers="suppliers" :loadNewData="loadNewData" :getSupplierAdmins="getSupplierAdmins" :search="search"></supplier-admin-table>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import supplierAdminTable from './components/supplierAdminTable'
export default {
  components: {
    supplierAdminTable
  },
  data () {
    return {
      statusValue: '',
      status: [{
        label: 'Бүгд',
        value: ''
      },
      {
        label: 'Баталгаажсан',
        value: 'confirmed'
      },
      {
        label: 'Баталгаажаагүй',
        value: 'unconfirmed'
      }],
      paginationToken: null,
      currentPage: 1,
      page: 1,
      search: '',
      supplierAdmins: [],
      activeTabStatus: 'all',
      totalCount: 0,
      isLoading: false,
      visibleSupplierDialog: false,
      supplierLoading: false,
      suppliers: []
    }
  },
  mounted () {
    this.getSupplierAdmins()
  },
  methods: {
    getSupplierAdminsNext () {
      if (this.paginationToken) {
        this.isLoading = true
        const query = '?name=' + this.search + '&status=' + this.statusValue
        services.getSupplierAdminsNext(query, { paginationToken: this.paginationToken }).then(response => {
          if (response.status === 'success') {
            this.paginationToken = response.data.PaginationToken
            this.count = this.count + response.data.Users.length
            this.suppliers = this.suppliers.concat(response.data.Users)
          }
          this.isLoading = false
        })
      }
    },
    loadNewData () {
      this.getSupplierAdminsNext()
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    async getSupplierAdmins () {
      this.isLoading = true
      const query = '?name=' + this.search + '&status=' + this.statusValue
      await services.getSupplierAdminsNext(query).then(response => {
        if (response.status === 'success') {
          this.paginationToken = response.data.PaginationToken
          this.count = response.data.Users.length
          this.suppliers = response.data.Users
        }
        this.isLoading = false
      })
    }
  }
}
</script>

<style></style>
